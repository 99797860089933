import Event from "../models/Event";
import EventGroup from "../models/EventGroup";

function GroupEventsByMonth(events : Event[]) : EventGroup[] {
    events.sort((a, b) => {
        if(!a.startDate || !b.startDate) {
            return 0;
        }
        return a.startDate < b.startDate ? -1 : 1;
    });
    const groups = [] as EventGroup[];
    let currentGroup : EventGroup|null = null;
    const currentDate = new Date();
    events.forEach((event) => {
        if(!event.startDate)
        {
            currentGroup = new EventGroup(event);
            groups.push(currentGroup);
            return;
        }
        if(!currentGroup) {
            currentGroup = new EventGroup(event);
            groups.push(currentGroup);
            return;
        }
        else if (currentGroup.name === "Happening Now"
            && event.startDate > currentDate) {
            currentGroup = new EventGroup(event);
            groups.push(currentGroup);
            return;
        }
        else if(currentGroup.month !== event.startDate.getMonth()
            || currentGroup.year !== event.startDate.getFullYear()) {
            currentGroup = new EventGroup(event);
            groups.push(currentGroup);
            return;
        }
        currentGroup.events.push(event);
    });
    return groups;
}

export default GroupEventsByMonth;
