import WSDCEvent from "../models/WSDCEvent";

function ParseWSDCEvents(eventListHTML: string) : WSDCEvent[] {
  const parser = new DOMParser();
  let parsed = parser.parseFromString(eventListHTML, "text/html");
  // Second script tag contains the event list
  let data = parsed.querySelectorAll('script[type="application/ld+json"]').item(1);
  let eventList = JSON.parse(data.innerHTML);
  
  return NormalizeEvents(eventList);
}

export function NormalizeEvents(events: any[]) : WSDCEvent[] {
  const ParsedEvents : WSDCEvent[] = [];
  events.forEach((event: any) => {
    const address = event.location.address;
    // Trimp parenthaticals from end of names (Trial Event)/(On Hiatus) etc.
    const parentheticalStart = event.name.indexOf(" (");
    let nameDisplay = parentheticalStart > 0 ?
      event.name.substring(0, parentheticalStart)
      : event.name;
    // Trim year from end of names
    const yearStart = nameDisplay.indexOf(" " + (new Date(event.endDate)).getFullYear());
    nameDisplay = yearStart > 0 ?
      nameDisplay.substring(0, yearStart)
      : nameDisplay;
    if(nameDisplay.endsWith("Trial Event"))
    {
      nameDisplay = nameDisplay.substring(0, nameDisplay.length - "Trial Event".length);
    }
    nameDisplay = nameDisplay.trim();

    const provinceDisplay = NormalizeProvince(address.addressRegion);
    const isTrial = event.name.toLocaleLowerCase().indexOf("trial event") > 0;
    const isOnHiatus = event.name.toLocaleLowerCase().indexOf("on hiatus") > 0
      || event.name.toLocaleLowerCase().indexOf("cancelled") > 0;

    const newEvent : WSDCEvent = {
        name: nameDisplay,
        url: event.url,
        address: address.streetAddress,
        city: address.addressLocality,
        province: provinceDisplay,
        country: address.addressCountry,
        startDate: new Date(event.startDate),
        endDate: new Date(event.endDate),
        eventDirector: event.director,
        isTrial: isTrial,
        isOnHiatus: isOnHiatus
    };
  
    ParsedEvents.push(newEvent);
  });

  return ParsedEvents;
}

function NormalizeProvince(province: string) : string {
  const upperProvince = province.toUpperCase().trim();
  switch(upperProvince) {
    case "AB":
      return "Alberta";
    case "BC":
      return "British Columbia";
    case "MB":
      return "Manitoba";
    case "NB":
      return "New Brunswick";
    case "NL":
      return "Newfoundland";
    case "NS":
      return "Nova Scotia";
    case "NT":
      return "Northwest Territories";
    case "NU":
      return "Nunavut";
    case "ON":
      return "Ontario";
    case "PE":
      return "Prince Edward Island";
    case "QC":
      return "Quebec";
    case "SK":
      return "Saskatchewan";
    case "YT":
      return "Yukon";
    case "AL":
      return "Alabama";
    case "AK":
      return "Alaska";
    case "AZ":
      return "Arizona";
    case "AR":
      return "Arkansas";
    case "CA":
      return "California";
    case "CO":
      return "Colorado";
    case "CT":
      return "Connecticut";
    case "DE":
      return "Delaware";
    case "FL":
      return "Florida";
    case "GA":
      return "Georgia";
    case "HI":
      return "Hawaii";
    case "ID":
      return "Idaho";
    case "IL":
      return "Illinois";
    case "IN":
      return "Indiana";
    case "IA":
      return "Iowa";
    case "KS":
      return "Kansas";
    case "KY":
      return "Kentucky";
    case "LA":
      return "Louisiana";
    case "ME":
      return "Maine";
    case "MD":
      return "Maryland";
    case "MA":
      return "Massachusetts";
    case "MI":
      return "Michigan";
    case "MN":
      return "Minnesota";
    case "MS":
      return "Mississippi";
    case "MO":
      return "Missouri";
    case "MT":
      return "Montana";
    case "NE":
      return "Nebraska";
    case "NV":
      return "Nevada";
    case "NH":
      return "New Hampshire";
    case "NJ":
      return "New Jersey";
    case "NM":
      return "New Mexico";
    case "NY":
      return "New York";
    case "NC":
      return "North Carolina";
    case "ND":
      return "North Dakota";
    case "OH":
      return "Ohio";
    case "OK":
      return "Oklahoma";
    case "OR":
      return "Oregon";
    case "PA":
      return "Pennsylvania";
    case "RI":
      return "Rhode Island";
    case "SC":
      return "South Carolina";
    case "SD":
      return "South Dakota";
    case "TN":
      return "Tennessee";
    case "TX":
      return "Texas";
    case "UT":
      return "Utah";
    case "VT":
      return "Vermont";
    case "VA":
      return "Virginia";
    case "WA":
      return "Washington";
    case "WV":
      return "West Virginia";
    case "WI":
      return "Wisconsin";
    case "WY":
      return "Wyoming";
    default:
      return province;
  }
}

export default ParseWSDCEvents;
