import React from 'react';
import GetDateDisplay from '../services/DateService';
import Event from '../models/Event';
import { CapitalizeProperNoun } from '../services/TextUtils';

interface EventListViewProps {
  event: Event;
}

function EventListView(props: EventListViewProps) {
  const event = props.event;
  const startDate = event.startDate;
  const endDate = event.endDate;

  let addressDisplay = event.city ? event.city : "";
  addressDisplay += event.province
    && event.province !== event.city
    && event.province !== event.country
    && addressDisplay ?
    `, ${event.province}` : "";
  addressDisplay = CapitalizeProperNoun(addressDisplay);
  const dateDisplay = startDate && endDate ? GetDateDisplay(startDate, endDate) : null;
  return (
    <div style={{width: "300px", height: "200px"}}>
      <div>
        <a href={event.url} target="_blank" rel="noreferrer">
          <h3 style={{
              margin: "0px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}>
            {event.name} {event.isTrial ? "(Trial Event)" : null}
          </h3>
        </a>
        <span>
            {addressDisplay}
        </span>
        <br />
        <span>
            {event.country}
        </span>
        <br />
        <span>
            {dateDisplay}
        </span>
      </div>
      <a href={event.url} target="_blank" rel="noreferrer">
        <img
          src={event.logoUrl}
          alt={event.name + " logo"}
          style={{maxWidth: "298px", maxHeight: "100px"}} />
      </a>
    </div>
  );
}

export default EventListView;
