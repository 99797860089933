import React,{ useState, useEffect } from 'react';
import EventListView from './components/EventListView';
import './App.css';
import ParseWSDCEvents from './services/WSDCService';
import Event from './models/Event';
import { GetEventsForWSDCEvents, GetEventsForNonWSDCEvents } from './services/AnnualEventService';
import GroupEventsByMonth from './services/EventService';
import IsEventInRegion from './services/GeographyService';
import WSDCEvent from './models/WSDCEvent';
import NonWSDCEvent from './models/NonWSDCEvent';

function App() {
  const [wsdcEvents, setWsdcEvents] = useState<WSDCEvent[]>([]);
  const [nonWsdcEvents, setNonWsdcEvents] = useState<NonWSDCEvent[]>([]);
  const [events, setEvents] = useState<Event[]>([]);
  const [shouldProcessEvents, setShouldProcessEvents] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>("All Events");
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function() {
        if (this.readyState === 4 && this.status === 200) {
          const parsedWsdcEvents = ParseWSDCEvents(xhttp.responseText);
          setWsdcEvents(parsedWsdcEvents);
          setShouldProcessEvents(true);
        }
    };
    xhttp.open("GET", "/events", true);
    xhttp.send();

    var xhttp2 = new XMLHttpRequest();
    xhttp2.onreadystatechange = function() {
        if (this.readyState === 4 && this.status === 200) {
          if(xhttp2.responseText) {
            const parsedNonWsdcEvents = JSON.parse(xhttp2.responseText);
            setNonWsdcEvents(parsedNonWsdcEvents);
            setShouldProcessEvents(true);  
          }
        }
    };
    xhttp2.open("GET", "/events/non-wsdc-events.json", true);
    xhttp2.send();
  }, []);

  if(shouldProcessEvents) {

    const wsdcEventsProcessed = GetEventsForWSDCEvents(wsdcEvents);
    const nonWsdcEventsProcessed = GetEventsForNonWSDCEvents(nonWsdcEvents);

    setEvents(wsdcEventsProcessed.concat(nonWsdcEventsProcessed));
    setShouldProcessEvents(false);
  }

  function updateFilter(event: React.ChangeEvent<HTMLSelectElement>) {
    setFilter(event.target.value);
  }

  function updateSearch(event: React.ChangeEvent<HTMLInputElement>) {
    setSearch(event.target.value.toLowerCase());
  }

  // First filter by region.
  let filteredEvents = events.filter((event) => {
    if(event.isOnHiatus)
    {
      //Omit events which are on hiatus from the list.
      return false;
    }
    if(!event.endDate)
    {
      console.log("No end date for event: " + event.name);
      return false;
    }
    const currentDate = new Date();
    const dayAfterEvent = new Date(event.endDate.getTime() + 86400000);
    if(!(dayAfterEvent > currentDate))
    {
      return false;
    }
    return IsEventInRegion(event, filter);
  });
  // Now apply the text search:
  filteredEvents = filteredEvents.filter((event) => {
    if(!search) {
      return true;
    }
    if(event.name.toLowerCase().indexOf(search) >= 0) { return true; }
    if(event.country.toLowerCase().indexOf(search) >= 0) { return true; }
    if(event.province.toLowerCase().indexOf(search) >= 0) { return true; }
    if(event.city.toLowerCase().indexOf(search) >= 0) { return true; }
    if(event.startDate
      && event.startDate.toLocaleString('default', { month: 'long' })
      .toLowerCase().indexOf(search) >= 0) { return true; }
    if(event.endDate
      && event.endDate.toLocaleString('default', { month: 'long' })
      .toLowerCase().indexOf(search) >= 0) { return true; }
  });
  const eventGroups = GroupEventsByMonth(filteredEvents);

  let content = eventGroups.map((group) => {
    const eventsList = group.events.map((event) => {
      const key = `${event.name}-${event.startDate}`;
      return (
        <EventListView event={event} key={key} />
      );
    });
    const backgroundColor = group.name === "Happening Now" ? '#bde3ff' : undefined;
    return (
      <div key={group.name} style={{backgroundColor: backgroundColor}}>
        <h3>{group.name}</h3>
        <div className="flexbox">
          {eventsList}
        </div>
      </div>
    );
  });

  return (
    <div className="App">
      <div className="ListNavigation flexbox">
        <div className="flexbox">
          <div style={{marginRight: '5px'}}>Region:</div>
          <select onChange={updateFilter}>
            <option value="All Events">Global</option>
            <option value="North America">North America</option>
            <option value="South America">South America</option>
            <option value="Europe">Europe</option>
            <option value="Asia">Asia</option>
            <option value="Oceania">Oceania</option>
            <option value="Southeast U.S.">Southeast U.S.</option>
            <option value="Northeast U.S.">Northeast U.S.</option>
            <option value="Central U.S.">Central U.S.</option>
            <option value="Southwest U.S.">Southwest U.S.</option>
            <option value="Northwest U.S.">Northwest U.S.</option>
          </select>
        </div>
        <div className="flexbox">
          <div style={{marginLeft: '10px', marginRight: '5px'}}>Search:</div>
          <input
            type="text"
            placeholder="Name/Location/Month"
            onChange={updateSearch}
            style={{maxWidth: '140px'}}></input>
        </div>
      </div>
      <div className="EventList">
        {content}
      </div>
    </div>
  );
}

export default App;